
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class SidebarUserManagement extends Vue {
    @Prop({ type: Function, default: () => { } })
    handleActive!: Function;
    @Prop({ default: "" }) activeState!: "";

    navlist = [
        {
            item: "Modules",
            node: "modules",
            route: "",
        },
        {
            item: "Screens",
            node: "screens",
            route: "",
        },
        {
            item: "User Creation",
            node: "user_creation",
            route: "",
        },
        {
            item: "Permissions",
            node: "permissions",
            route: "",
        },
        {
            item: "Groups",
            node: "group",
            route: "",
        },
        {
            item: "User Permission",
            node: "user",
            route: "",
        },
    ];
}
