
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import UserManagementService from "@/services/user-management";
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import SidebarUserManagement from "@/components/user-management-sidebar.vue"
import ComponentSidebar from "@/components/user-mangement-component-sidebar.vue"
import ModuleSidebar from "@/components/user-mangement-module-sidebar.vue"
import HRMService from "@/services/hrm-service";
import PopUp from "@/components/PopUp.vue";

@Component({
    components: { Header, Loader, SidebarUserManagement, ComponentSidebar, ModuleSidebar, PopUp },
})
export default class UserManagement extends Vue {

    loading = true;
    public currentPage = 1;
    public selected_screen: any = [];
    public selected_permissions: any = [];
    public selected_module_permissions: any = [];
    public get_permissions: any = [];
    public selected_groups: any = [];
    public selected_roles: any = [];
    public selected_routes: any = [];
    public selected_user: any = [];
    public permitUser: any = [];
    public selected_all_user: any = [];
    public selected_employees: any = [];
    public selected_employee: any = [];
    public search_user: any = [];
    openModule = false;
    openScreen = false;
    openPermission = false;
    openGroup = false;
    module = [];
    screen = [];
    permission = [];
    group = [];
    module_name = [];
    permissionTitle = '';
    groupTitle = '';
    userTitle = '';
    userId = '';
    screenTitle = '';
    description = '';
    route = '';
    component = '';
    componentId = 0;
    moduleId = 0;
    public ModuleObject: any = {};
    public screenObject: any = {};
    public permissionObject: any = {};
    public groupObject: any = {};
    public userObject: any = {};
    public userCreation: any = {};
    permissionItems = null
    permissionId = null
    modalShow = false;
    modalMessage = "";
    modalType = "";
    modalErrorMessage = [];
    errorMessages: any = [];
    saveData = true;

    closeModal() {
        this.modalShow = false;
        this.modalMessage = "";
        this.modalType = "";
        this.errorMessages = [];
    }
    showModal(message: string, type: "success" | "error") {
        this.modalShow = true;
        this.modalMessage = message;
        this.modalType = type;
    }
    showModalErrors(message: [], type: "errorArray") {
        this.modalShow = true;
        this.modalErrorMessage = message;
        this.modalType = type;
    }
    formatPermissions(permissionArray: any) {
        return permissionArray.join(', ');
    }
    toggleModuleDropdown() {
        this.openModule = !this.openModule;
        this.getScreenModule();
    }
    toggleScreenDropdown() {
        this.openScreen = !this.openScreen;
    }
    togglePermissionDropdown() {
        this.openPermission = !this.openPermission;
        this.getPermission();
    }
    toggleGroupDropdown() {
        this.openGroup = !this.openGroup;
        this.get_groups();
    }
    public permission_options: any = [];
    group_options = [];
    public module_options: any = [];
    get rows() {
        return this.selected_permissions.length;
    }
    onCheckboxChange(item: any) {

    }
    selectAll = false;
    selectAllPermissions() {
        for (const item of this.selected_permissions) {
            item.create = this.selectAll;
            item.view = this.selectAll;
            item.edit = this.selectAll;
            item.delete = this.selectAll;
        }
    }
    activeState: string = "user_creation";
    handleActive(item: any) {
        this.activeState = item;
        this.saveData = true;
    }
    retreive() {
        // this.loading = true;
        UserManagementService.get_all_screens()
            .then((res) => {
                this.selected_permissions = res.data.data;
                this.saveData = true;
                // this.loading = false;
            })
            .catch((e) => {
                this.loading = false;
                console.log(e);
            });
    }
    getEmployees() {
        HRMService.getAll()
            .then((res) => {
                this.selected_employee = res.data
            })
            .catch((e) => {
                this.loading = false;
                console.log(e);
            });
    }
    getPermission() {
        UserManagementService.get_screen_permission()
            .then((res) => {
                this.permission_options = res.data;
            })
            .catch((e) => {
                this.loading = false;
                console.log(e);
            });
    }
    get_groups() {
        UserManagementService.get_groups()
            .then((res) => {
                this.selected_groups = res.data.data
            })
            .catch((e) => {
                this.loading = false;
                console.log(e);
            });
    }
    getUser() {
        UserManagementService.get_user()
            .then((res) => {
                this.selected_user = res.data.data
            })
            .catch((e) => {
                this.loading = false;
                console.log(e);
            });
    }
    getScreenModule() {
        UserManagementService.get_screen_modules()
            .then((res) => {
                this.module_options = res.data.module
            })
            .catch((e) => {
                this.loading = false;
                console.log(e);
            });
    }
    getAllUsers() {
        UserManagementService.getAll_user()
            .then((res) => {
                this.permitUser = res.data.data
            })
            .catch((e) => {
                this.loading = false;
                console.log(e);
            });
    }
    mounted() {
        this.retreive();
        this.get_groups();
        this.getUser();
        this.getAllUsers();
        this.getScreenModule();
        this.getPermission();
        this.getEmployees();
    }
    saveUser() {
        if (!this.userCreation.employee_title) {
            this.errorMessages.push("Please enter the Title.");
        }
        if (!this.userCreation.first_name) {
            this.errorMessages.push("Please enter the First Name.");
        }
        if (!this.userCreation.last_name) {
            this.errorMessages.push("Please enter the Last Name.");
        }
        if (!this.userCreation.email) {
            this.errorMessages.push("Please enter the Email.");
        }
        // if (!this.userCreation.password) {
        //     this.errorMessages.push("Please enter the start Password.");
        // }
        if (this.errorMessages.length > 0) {
            this.showModalErrors(this.errorMessages, "errorArray");
        }
        const selected_title: any = this.userCreation.employee_title;
        const emp_id = this.selected_employee
            .filter((employee: any) => employee.title === selected_title)
            .map((employee: any) => employee.id)[0];
        const userCreation: any = {
            employee_id: emp_id,
            first_name: this.userCreation.first_name,
            last_name: this.userCreation.last_name,
            email: this.userCreation.email
        }
        if (this.errorMessages.length === 0) {
            UserManagementService.create_User(userCreation)
                .then((res) => {
                    this.resetUser();
                    this.getAllUsers();
                    this.showModal("User Created Successfully", "success")
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e);
                    this.showModal("Not Created Something Wrong", "error");
                });
        }
    }
    saveModule() {
        if (!this.ModuleObject.api_route) {
            this.errorMessages.push("Please enter the Module.");
        }
        if (this.errorMessages.length > 0) {
            this.showModalErrors(this.errorMessages, "errorArray");
        }
        const moduleObject: any = {
            api_route: this.ModuleObject.api_route
        }
        if (this.errorMessages.length === 0) {
            UserManagementService.post_screen_modules(moduleObject)
                .then((res) => {
                    this.showModal("Module Created Successfully", "success")
                    this.resetModule();
                    this.getScreenModule();
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e);
                    this.showModal("Not Created Something Wrong", "error");
                });
        }
    }
    // Not done with validations for saving permissions
    savePermission() {
        const selectedScreens = this.selected_permissions.filter(
            (permission: any) =>
                permission.create || permission.view || permission.edit || permission.delete
        );
        let isChecked = false;
        this.selected_permissions.forEach((item: any) => {
            if (item.create || item.view || item.edit || item.delete) {
                isChecked = true;
            }
        });
        const permissionObject: any = {
            title: this.permissionTitle,
            client_permission: {},
            screen: [],
            server_permission: {}
        };
        if (!permissionObject.title) {
            this.errorMessages.push("Please enter the Title.");
        }
        if (!isChecked) {
            this.errorMessages.push("Please check any one screen for permission.");
        }
        if (this.errorMessages.length > 0) {
            this.showModalErrors(this.errorMessages, "errorArray");
        }

        this.selected_permissions.forEach((permission: any) => {
            const screenPermission = {
                create: permission.create || false,
                view: permission.view || false,
                update: permission.edit || false,
                delete: permission.delete || false,
            };

            permissionObject.client_permission[permission.name] = screenPermission;
            // permissionObject.server_permission[permission.module_name__api_route] = screenPermission
            // permissionObject.screen.push(permission.id);
            // const moduleRoutes = permission.module_name__api_route;
            // moduleRoutes.forEach((route: any) => {
            //     permissionObject.server_permission[route] = screenPermission;
            // });
        });

        selectedScreens.forEach((permission: any) => {
            const screenPermission = {
                create: permission.create || false,
                view: permission.view || false,
                update: permission.edit || false,
                delete: permission.delete || false,
            };
            permissionObject.screen.push(permission.id);
            const moduleRoutes = permission.module_name__api_route;
            moduleRoutes.forEach((route: any) => {
                permissionObject.server_permission[route] = screenPermission;
            });
        });
        // console.log(permissionObject)
        if (this.errorMessages.length === 0) {
            UserManagementService.post_screen_permission(permissionObject)
                .then((res) => {
                    this.resetPermissions();
                    this.getPermission();
                    this.showModal("Permission Created Successfully", "success")
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e);
                    this.showModal("Not Created Something Wrong", "error");
                });
        }
    }
    saveGroup() {
        if (!this.groupObject.title) {
            this.errorMessages.push("Please enter the Title.");
        }
        if (this.permission.length === 0) {
            this.errorMessages.push("Please select the permission.");
        }
        if (this.errorMessages.length > 0) {
            this.showModalErrors(this.errorMessages, "errorArray");
        }
        const permission_id: any = this.permission
        const selectedPermissionId = permission_id.map((permission: any) => {
            const option = this.permission_options.find((option: any) => option.title === permission)
            return option?.id;
        })
        const groupObject = {
            title: this.groupObject.title,
            permissions: selectedPermissionId,
        };
        if (this.errorMessages.length === 0) {
            UserManagementService.post_group_permissoin(groupObject)
                .then((res) => {
                    this.resetGroup();
                    this.get_groups();
                    this.showModal("Group Created Successfully", "success")
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e);
                    this.showModal("Not Created Something Wrong", "error");
                });
        }
    }
    saveUserPermission() {
        if (!this.userObject.employee_title) {
            this.errorMessages.push("Please enter the User Title.");
        }
        // if (!this.userObject.username) {
        //     this.errorMessages.push("Please enter the User Name.");
        // }
        if (!this.userObject.title) {
            this.errorMessages.push("Please enter the User Permission Title.");
        }
        if (this.permission.length === 0 && this.group.length === 0) {
            this.errorMessages.push("Please select either permission or group.");
        }
        // if (this.group.length === 0) {
        //     this.errorMessages.push("Please select the group.");
        // }
        if (this.errorMessages.length > 0) {
            this.showModalErrors(this.errorMessages, "errorArray");
        }
        const permission_id: any = this.permission
        const selectedPermissionId = permission_id.map((permission: any) => {
            const option = this.permission_options.find((option: any) => option.title === permission)
            return option?.id;
        })
        const group_id: any = this.group
        const selectedGroupId = group_id.map((group: any) => {
            const option = this.selected_groups.find((option: any) => option.title === group)
            return option?.id;
        })
        const selected_user_title: any = this.userObject.employee_title;
        // console.log(selected_user_title)
        const user_id = this.permitUser
            .filter((user: any) => user.employee_title === selected_user_title)
            .map((user: any) => user.id)[0];
        console.log(user_id)
        const userObject = {
            user: user_id,
            title: this.userObject.title,
            permissions: selectedPermissionId,
            group: selectedGroupId,
        };
        if (this.errorMessages.length === 0) {
            UserManagementService.post_user_permission(userObject)
                .then((res) => {
                    this.resetUserPermission();
                    this.getUser();
                    this.showModal("User Permission Created Successfully", "success")
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e);
                    this.showModal("Not Created Something Wrong", "error");
                });
        }
    }
    saveScreen() {
        if (!this.screenObject.name) {
            this.errorMessages.push("Please enter the Name.");
        }
        if (!this.screenObject.description) {
            this.errorMessages.push("Please enter the Description.");
        }
        if (!this.screenObject.routes) {
            this.errorMessages.push("Please enter the Route.");
        }
        // if (!this.screenObject.component) {
        //     this.errorMessages.push("Please enter the Component.");
        // }
        if (this.module.length === 0) {
            this.errorMessages.push("Please select the module.");
        }
        if (this.errorMessages.length > 0) {
            this.showModalErrors(this.errorMessages, "errorArray");
        }
        const selectedModuleIds = this.module.map((module) => {
            const option = this.module_options.find((option: any) => option.api_route === module);
            return option.id;
        });

        const screenObject = {
            name: this.screenObject.name,
            description: this.screenObject.description,
            routes: this.screenObject.routes,
            component: this.screenObject.component,
            module_name: selectedModuleIds
        }
        if (this.errorMessages.length === 0) {
            UserManagementService.create_screen(screenObject)
                .then((res) => {
                    this.resetScreen();
                    this.retreive();
                    this.showModal("Screen Created Successfully", "success")
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e);
                    this.showModal("Not Created Something Wrong", "error");
                });
        }
    }
    retreivePermission() {
        this.saveData = false;
        const selectedPermission: any = this.permission_options.find(
            (permission: any) => permission.id === this.permissionId
        );

        if (selectedPermission) {
            this.permissionTitle = selectedPermission.title;
            this.permissionObject.id = selectedPermission.id;
            // this.permissionObject.screen = selectedPermission.screen;
            const existingModuleRoutes: any = {};
            const existingId: any = {};
            this.selected_permissions.forEach((screen: any) => {
                existingModuleRoutes[screen.name] = screen.module_name__api_route;
            });
            this.selected_permissions.forEach((screen: any) => {
                existingId[screen.name] = screen.id;
            });

            this.selected_permissions = [];

            for (const screenName in selectedPermission.client_permission) {
                if (selectedPermission.client_permission.hasOwnProperty(screenName)) {
                    const screenPermission = selectedPermission.client_permission[screenName];
                    const newScreen = {
                        id: existingId[screenName] || null,
                        name: screenName,
                        description: "",
                        create: screenPermission.create,
                        view: screenPermission.view,
                        edit: screenPermission.update,
                        delete: screenPermission.delete,
                        module_name__api_route: existingModuleRoutes[screenName] || [],
                    };
                    this.selected_permissions.push(newScreen);
                }
            }
        }
    }
    editUserCreationToggle(data: any) {
        this.saveData = false;
        this.userCreation = data;
        this.$root.$emit("bv::toggle::collapse", "sidebar-variant-create-user-creation");
    }
    editUser() {
        if (!this.userCreation.employee_title) {
            this.errorMessages.push("Please enter the Title.");
        }
        if (!this.userCreation.first_name) {
            this.errorMessages.push("Please enter the First Name.");
        }
        if (!this.userCreation.last_name) {
            this.errorMessages.push("Please enter the Last Name.");
        }
        if (!this.userCreation.email) {
            this.errorMessages.push("Please enter the Email.");
        }
        if (this.errorMessages.length > 0) {
            this.showModalErrors(this.errorMessages, "errorArray");
        }
        if (this.errorMessages.length === 0) {
            UserManagementService.update_User(this.userCreation.id, this.userCreation)
                .then((res) => {
                    this.resetUser();
                    this.getAllUsers();
                    this.showModal("User Updated Successfully", "success")
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e);
                    this.showModal("Not Updated Something Wrong", "error");
                });
        }
    }
    editPermission() {
        const selectedScreens = this.selected_permissions.filter(
            (permission: any) =>
                permission.create || permission.view || permission.edit || permission.delete
        );
        let isChecked = false;
        this.selected_permissions.forEach((item: any) => {
            if (item.create || item.view || item.edit || item.delete) {
                isChecked = true;
            }
        });
        if (!this.permissionTitle) {
            this.errorMessages.push("Please enter the Title.");
        }
        if (!isChecked) {
            this.errorMessages.push("Please check any one screen for permission.");
        }
        if (this.errorMessages.length > 0) {
            this.showModalErrors(this.errorMessages, "errorArray");
        }
        const permissionObject: any = {
            title: this.permissionTitle,
            client_permission: {},
            screen: [],
            server_permission: {}
        };
        this.selected_permissions.forEach((permission: any) => {
            const screenPermission = {
                create: permission.create || false,
                view: permission.view || false,
                update: permission.edit || false,
                delete: permission.delete || false,
            };

            permissionObject.client_permission[permission.name] = screenPermission;

            // permissionObject.server_permission[permission.module_name__api_route] = screenPermission
            // const moduleRoutes = permission.module_name__api_route;
            // moduleRoutes.forEach((route: any) => {
            //     permissionObject.server_permission[route] = screenPermission;
            // });
        });
        selectedScreens.forEach((permission: any) => {
            const screenPermission = {
                create: permission.create || false,
                view: permission.view || false,
                update: permission.edit || false,
                delete: permission.delete || false,
            };
            permissionObject.screen.push(permission.id);
            const moduleRoutes = permission.module_name__api_route;
            moduleRoutes.forEach((route: any) => {
                permissionObject.server_permission[route] = screenPermission;
            });
        });

        if (this.errorMessages.length === 0) {
            UserManagementService.update_screen_permission(this.permissionObject.id, permissionObject)
                .then((res) => {
                    this.retreive()
                    this.getPermission();
                    this.resetPermissions();
                    this.showModal("Permission Updated Successfully", "success");
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e);
                    this.showModal("Not Updated Something Wrong", "error");
                });
        }
    }
    editGroupToggle(data: any) {
        this.saveData = false;
        this.groupObject = data;
        this.permission = this.groupObject.permission_title.slice();
        this.$root.$emit("bv::toggle::collapse", "sidebar-variant-create-group");
    }
    editGroup() {
        if (!this.groupObject.title) {
            this.errorMessages.push("Please enter the Title.");
        }
        if (this.permission.length === 0) {
            this.errorMessages.push("Please select the permission.");
        }
        if (this.errorMessages.length > 0) {
            this.showModalErrors(this.errorMessages, "errorArray");
        }
        const permission_id: any = this.permission
        const selectedPermissionId = permission_id.map((permission: any) => {
            const option = this.permission_options.find((option: any) => option.title === permission)
            return option?.id;
        })
        this.groupObject.permissions = selectedPermissionId;
        if (this.errorMessages.length === 0) {
            UserManagementService.update_group_permission(this.groupObject.id, this.groupObject)
                .then((res) => {
                    this.resetGroup()
                    this.get_groups();
                    this.showModal("Group Updated Successfully", "success");
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e);
                    this.showModal("Not Updated Something Wrong", "error");
                });
        }
    }
    editUserToggle(data: any) {
        this.saveData = false;
        this.permitUser = [];
        this.userObject = data;
        this.permitUser.push(this.userObject.id);
        this.permission = this.userObject.permission_title.slice();
        this.group = this.userObject.group_title.slice();
        this.$root.$emit("bv::toggle::collapse", "sidebar-variant-create-user");
    }
    editUserPermission() {
        if (!this.userObject.employee_title) {
            this.errorMessages.push("Please enter the User Title.");
        }
        // if (!this.userObject.username) {
        //     this.errorMessages.push("Please enter the User Name.");
        // }
        if (!this.userObject.title) {
            this.errorMessages.push("Please enter the User Permission Title.");
        }
        if (this.permission.length === 0 && this.group.length === 0) {
            this.errorMessages.push("Please select either permission or group.");
        }
        // if (this.group.length === 0) {
        //     this.errorMessages.push("Please select the group.");
        // }
        if (this.errorMessages.length > 0) {
            this.showModalErrors(this.errorMessages, "errorArray");
        }
        const permission_id: any = this.permission
        const selectedPermissionId = permission_id.map((permission: any) => {
            const option = this.permission_options.find((option: any) => option.title === permission)
            return option?.id;
        })
        const group_id: any = this.group
        const selectedGroupId = group_id.map((group: any) => {
            const option = this.selected_groups.find((option: any) => option.title === group)
            return option?.id;
        })
        this.userObject.permissions = selectedPermissionId;
        this.userObject.group = selectedGroupId;
        if (this.errorMessages.length === 0) {
            UserManagementService.update_user_permission(this.userObject.id, this.userObject)
                .then((res) => {
                    this.resetUserPermission();
                    this.getUser();
                    this.showModal("User Permission Updated Successfully", "success")
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e);
                    this.showModal("Not Updated Something Wrong", "error");
                });
        }
    }
    editScreenToggle(data: any) {
        this.saveData = false;
        this.screenObject = data;
        this.module = this.screenObject.module_name__api_route.slice();
        this.$root.$emit("bv::toggle::collapse", "sidebar-variant-create-screen");
    }
    editScreen() {
        if (!this.screenObject.name) {
            this.errorMessages.push("Please enter the Name.");
        }
        if (!this.screenObject.description) {
            this.errorMessages.push("Please enter the Description.");
        }
        if (!this.screenObject.routes) {
            this.errorMessages.push("Please enter the Route.");
        }
        // if (!this.screenObject.component) {
        //     this.errorMessages.push("Please enter the Component.");
        // }
        if (this.module.length === 0) {
            this.errorMessages.push("Please select the module.");
        }
        if (this.errorMessages.length > 0) {
            this.showModalErrors(this.errorMessages, "errorArray");
        }
        const module_id: any = this.module;
        const selectedModuleIds = module_id.map((module: any) => {
            const option = this.module_options.find((option: any) => option.api_route === module);
            return option?.id;
        });
        this.screenObject.module_name = selectedModuleIds;
        if (this.errorMessages.length === 0) {
            UserManagementService.update_screen(this.screenObject.id, this.screenObject)
                .then((res) => {
                    this.resetScreen();
                    this.retreive();
                    this.showModal("Screen Updated Successfully", "success")
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e);
                    this.showModal("Not Updated Something Wrong", "error");
                });
        }
    }
    editModuleToggle(data: any) {
        this.saveData = false;
        this.ModuleObject = data;
        this.$root.$emit("bv::toggle::collapse", "sidebar-variant-create-module")
    }
    editModule() {
        if (!this.ModuleObject.api_route) {
            this.errorMessages.push("Please enter the Module.");
        }
        if (this.errorMessages.length > 0) {
            this.showModalErrors(this.errorMessages, "errorArray");
        }
        if (this.errorMessages.length === 0) {
            UserManagementService.update_module(this.ModuleObject.id, this.ModuleObject)
                .then((res) => {
                    this.resetModule();
                    this.getScreenModule();
                    this.showModal("Module Updated Successfully", "success")
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e);
                    this.showModal("Not Updated Something Wrong", "error");
                });
        }
    }
    deleteUser(item: any) {
        UserManagementService.deleteUser(item.id)
            .then((res) => {
                this.getAllUsers();
                this.showModal("User Deleted Successfully", "success")
            })
            .catch((e) => {
                this.loading = false;
                console.log(e);
                this.showModal("Not Deleted Something Wrong", "error");
            });
    }
    deleteModule(item: any) {
        UserManagementService.delete_module(item.id)
            .then((res) => {
                this.getScreenModule();
                this.showModal("Module Deleted Successfully", "success")
            })
            .catch((e) => {
                this.loading = false;
                console.log(e);
                this.showModal("Not Deleted Something Wrong", "error");
            });
    }
    deleteScreen(item: any) {
        UserManagementService.delete_screen(item.id)
            .then((res) => {
                this.retreive();
                this.showModal("Screen Deleted Successfully", "success")
            })
            .catch((e) => {
                this.loading = false;
                console.log(e);
                this.showModal("Not Deleted Something Wrong", "error");
            });
    }
    deletePermission() {
        UserManagementService.delete_screen_permission(this.permissionObject.id)
            .then((res) => {
                this.resetPermissions();
                this.retreive();
                this.getPermission();
                this.showModal("Permission Deleted Successfully", "success")
            })
            .catch((e) => {
                this.loading = false;
                console.log(e);
                this.showModal("Not Deleted Something Wrong", "error");
            });
    }
    deleteGroup(item: any) {
        UserManagementService.delete_group(item.id)
            .then((res) => {
                this.get_groups();
                this.showModal("Group Deleted Successfully", "success")
            })
            .catch((e) => {
                this.loading = false;
                console.log(e);
                this.showModal("Not Deleted Something Wrong", "error");
            });
    }
    deleteUserPermission(item: any) {
        UserManagementService.delete_user_permission(item.id)
            .then((res) => {
                this.getUser();
                this.showModal("User Permission Deleted Successfully", "success")
            })
            .catch((e) => {
                this.loading = false;
                console.log(e);
                this.showModal("Not Deleted Something Wrong", "error");
            });
    }
    componentButton(item: any) {
        this.componentId = item.id;
        // console.log(this.itemId);
        this.$root.$emit("bv::toggle::collapse", "sidebar-variant-component");
    }
    moduleButton(item: any) {
        this.moduleId = item.id;
        // console.log(item.id)
        this.$root.$emit("bv::toggle::collapse", "sidebar-variant-module")
    }
    resetModule() {
        this.saveData = true;
        this.ModuleObject = {
            api_route: '',
        }
    }
    resetUser() {
        this.saveData = true;
        this.userCreation = {
            first_name: '',
            last_name: '',
            username: '',
            email: ''
        }
    }
    resetScreen() {
        this.saveData = true;
        this.screenObject = {
            screenTitle: '',
            description: '',
            route: '',
            component: '',
        }
        this.module = []
    }
    resetPermissions() {
        this.permissionTitle = '';
        this.selected_permissions = [];
        this.permissionId = null;
        this.retreive();
        this.saveData = true;
    }
    resetGroup() {
        this.saveData = true;
        this.groupObject = {
            groupTitle: '',
        }
        this.permission = []
    }
    resetUserPermission() {
        this.saveData = true;
        this.userObject = {
            id: '',
            title: ''
        }
        this.permission = [];
        this.group = [];
    }
    index = 0;
    search_employee(title: any, index: any) {
        this.index = index;
        if (title.length > 2) {
            UserManagementService.get_Employee_by_Title(title)
                .then((res) => {
                    this.selected_employees = res.data;
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        if (title.length == 0) {
            this.selected_employees = [];
        }
    }
    get_data(data: any) {
        this.userCreation.employee_title = data.title;
        this.userCreation.first_name = data.first_name;
        this.userCreation.last_name = data.last_name;
        this.selected_employees = [];
    }
    search_user_by_title(title: any, index: any) {
        this.index = index;
        if (title.length > 2) {
            UserManagementService.get_User_by_Title(title)
                .then((res) => {
                    this.search_user = res.data.data;
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        if (title.length == 0) {
            this.search_user = [];
        }
    }
    get_user_data(data: any) {
        this.userObject.employee_title = data.employee_title;
        this.search_user = [];
    }
}
