
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import UserManagementService from '@/services/user-management';
@Component
export default class ComponentSidebar extends Vue {
    @Prop({ type: Number }) id!: number;
    public items: any = {}
    public compoenents_fields = [
        { key: 'components', label: 'Components' },
        { key: 'create', label: 'Create' },
        { key: 'view', label: 'View' },
        { key: 'edit', label: 'Edit' },
        { key: 'delete', label: 'Delete' },
    ];

    // get_data(id: any) {
    //     UserManagementService.get_components(id)
    //         .then((res) => {
    //             this.items = res.data.data[0].components[0];
    //         })
    // }
    get_data(id: any) {
        UserManagementService.get_components(id)
            .then((res) => {
                this.items = res.data.data[0].components[0];
                // console.log(this.items);
            });
    }
    // mounted(id: any) {
    //     this.get_data(id)
    // }
    @Watch("id")
    onModalShowChanged(newValue: any, oldValue: any) {
        this.get_data(this.id);
    }
}
