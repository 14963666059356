import { render, staticRenderFns } from "./user-mangement-module-sidebar.vue?vue&type=template&id=e5db761c&scoped=true"
import script from "./user-mangement-module-sidebar.vue?vue&type=script&lang=ts"
export * from "./user-mangement-module-sidebar.vue?vue&type=script&lang=ts"
import style0 from "./user-mangement-module-sidebar.vue?vue&type=style&index=0&id=e5db761c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5db761c",
  null
  
)

export default component.exports