
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import UserManagementService from '@/services/user-management';
@Component
export default class ModuleSidebar extends Vue {
    @Prop({ type: Number }) id!: number;
    public items: any = {}
    moduleTitle = '';

    get_data(id: any) {
        UserManagementService.get_screen_module_by_id(id)
            .then((res) => {
                this.items = res.data.data;
            });
    }

    saveModule() {

    }
    resetModule() {

    }
    @Watch("id")
    onModalShowChanged(newValue: any, oldValue: any) {
        this.get_data(this.id);
    }
}
